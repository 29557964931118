.Scopa {
  text-align: center;
  height: 100%;
}

.Card {
  height: 20vmin;
  width: 15vmin;
  pointer-events: none;
  padding-left: 20px;
}

.CardButton {
    background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor:pointer;
  overflow: hidden;
  outline:none;
}


.Scopa-header {
  display: inline-block;
  width: 100%;
  height: 95%;

  font-size: calc(10px + 2vmin);
  color: white;
  background-color: #282c34;  
}

.table {
  display: inline-block;
  
  height: 75%;
  align-items: center;
  justify-content: center;
  background-color: #59954A;
  width:100%;
}

.hand {
  display: inline-block;
  min-height: 25%;
  align-items: center;
  justify-content: center;
  background-color: #84563C;
  width:100%;
}


.facebook {
	color: white;
  font-size: large;
  padding: 20px;
  font-size: 30px;
  text-align: center;
  text-decoration: none;
  background: #3B5998;
  border: none;
}

.hud {
  display: inline-block;
  text-align: left;
  margin-left:20px;
  height: 5%;
}

/* Style all font awesome icons */
.fa {
  padding-left: 10px;
  padding-right: 20px;
  font-size: 30px;
  width: 50px;
  text-align: center;
  text-decoration: none;
}

/* Add a hover effect if you want */
.fa:hover {
  opacity: 0.7;
}

/* Set a specific color for each brand */

/* Facebook */
.fa-facebook {
  background: #3B5998;
  color: white;
}

/* Twitter */
.fa-twitter {
  background: #55ACEE;
  color: white;
}

.logo {
  width: 250px;
  height: 250px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.startButton {
  padding-left: 10px;
  padding-right: 20px;
  font-size: 30px;
  text-align: center;
  text-decoration: none;
  color: #472300;
  background:rgb(98, 160, 101);
  border: none;
}

.backButton {
  padding-left: 10px;
  padding-right: 20px;
  font-size: 30px;
  text-align: center;
  text-decoration: none;
  color: #472300;
  background:rgb(98, 160, 101);
  border: none;
}

.aboutUsButton {
  padding-left: 10px;
  padding-right: 20px;
  font-size: 30px;
  text-align: center;
  text-decoration: none;
  color: #472300;
  background:rgb(98, 160, 101);
  border: none;
}

body {
  background: #59954A;
  font-size: 30px;
  height: 100%;
}

input {
  font-size: 30px;
}

.loginFailed {
  text-align: center;
  height: 100%;
}

.error {
  color: #472300;
  background-color: #E0BBE4;
  display:inline-block;
  padding: 10px;
}

.player {
  margin-right: 10px;
}

.turn {
  background-color: #E0BBE4;
  color: #472300;
  margin-right: 10px;
}

html {
  height: 100%;
}

#root {
  height: 100%;
  color:#472300;
}